import {useContext, useCallback} from 'react';

import EnvContext from '../context/EnvContext';

/**
 * @returns {{siteUrl, description, appUrl: (function(*=): (null|string)), title, signUpRoute: (function(): string), signInRoute: (function(): string)}}
 */
export default function useEnvContext() {
	const {title, description, siteUrl, appDomain} = useContext(EnvContext);

	const appUrl = useCallback(
		(uri) => {
			if (typeof uri !== 'string') {
				return null;
			}

			let url = appDomain;

			if (url.substr(url.length - 1, 1) === '/') {
				url = url.substr(0, url.length - 1);
			}

			return uri.substr(0, 1) === '/' ? url + uri : `${url}/${uri}`;
		},
		[appDomain]
	);

	const signUpRoute = useCallback(() => appUrl('registracija'), [appUrl]);

	const signInRoute = useCallback(() => appUrl('prijava'), [appUrl]);

	return {title, description, siteUrl, appUrl, signUpRoute, signInRoute, appDomain};
}
