import React from 'react';
import PropTypes from 'prop-types';
import styled, {keyframes} from 'styled-components';
import {Link} from 'gatsby';
import {OutboundLink} from 'gatsby-plugin-google-gtag';

import {CONTAINER_WIDTH, FONT_FAMILY, NAV_HEIGHT, PRIMARY_COLOR, PRIMARY_COLOR_2, PRIMARY_COLOR_3} from '../constants';
import {blogRoute, examplesRoute, faqRoute, homepageRoute, howItWorksRoute, pricesRoute, whyQrCjenikRoute} from '../routes';
import useEnvContext from '../hooks/useEnvContext';

export default function Nav({background}) {
	const {appUrl} = useEnvContext();

	const handleOpenMobileMenu = async () => {
		document.getElementById('mobile-menu').classList.add('show');
	};

	const startClosingMobileMenu = async () => {
		document.getElementById('mobile-menu').classList.add('out');

		setTimeout(() => {
			const mobileMenu = document.getElementById('mobile-menu');
			if (mobileMenu) {
				mobileMenu.classList.remove('show');
				mobileMenu.classList.remove('out');
			}
		}, 500);
	};

	return (
		<Wrapper id="nav" className="show" $background={background}>
			<div />
			<div>
				<div>
					<Link to={homepageRoute()} className="logo">
						QR-Cjenik.hr
					</Link>
					<div className="flex" />
					<div className="link">
						<Link to={howItWorksRoute()}>KAKO RADI?</Link>
					</div>
					<div className="link">
						<Link to={examplesRoute()}>PRIMJERI</Link>
					</div>
					<div className="link">
						<Link to={whyQrCjenikRoute()}>ZAŠTO QR-CJENIK</Link>
					</div>
					<div className="link">
						<Link to={pricesRoute()}>CIJENE</Link>
					</div>
					<div className="link">
						<OutboundLink href={appUrl('/registracija')}>REGISTRACIJA</OutboundLink>
					</div>
					<div className="link sign-in">
						<OutboundLink href={appUrl('/prijava')}>PRIJAVA</OutboundLink>
					</div>
					<div className="link mobile-link">
						<button type="button" className="mobile-button" id="btn-mobile-menu" onClick={handleOpenMobileMenu}>
							&nbsp;
							<span />
							<span />
							<span />
						</button>
					</div>
				</div>
			</div>
			<div id="mobile-menu" className="mobile-wrapper">
				<nav>
					<div />
					<div>
						<Link to={homepageRoute()} className="logo" onClick={startClosingMobileMenu}>
							QR-Cjenik.hr
						</Link>
					</div>
					<div>
						<button onClick={startClosingMobileMenu} type="button">
							<span>&times;</span>
						</button>
					</div>
				</nav>
				<nav id="mobile-links">
					<OutboundLink href={appUrl('/prijava')} className="highlight">
						Prijava korisnika
					</OutboundLink>
					<OutboundLink href={appUrl('/prijava')}>Registracija</OutboundLink>
					<Link to={howItWorksRoute()} onClick={startClosingMobileMenu}>
						Kako radi QR Cjenik?
					</Link>
					<Link to={examplesRoute()} onClick={startClosingMobileMenu}>
						Kako izgleda cjenik?
					</Link>
					<Link to={whyQrCjenikRoute()} onClick={startClosingMobileMenu}>
						Zašto odabrati QR-Cjenik.hr?
					</Link>
					<Link to={pricesRoute()} onClick={startClosingMobileMenu}>
						Cijene
					</Link>
					<Link to={faqRoute()} onClick={startClosingMobileMenu}>
						Često postavljena pitanja
					</Link>
					<Link to={blogRoute()} onClick={startClosingMobileMenu}>
						Blog
					</Link>
				</nav>
			</div>
		</Wrapper>
	);
}

Nav.propTypes = {
	background: PropTypes.string.isRequired
};

const scaleInTr = keyframes`
  0% {
    transform: scale(0);
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    transform: scale(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
`;

const scaleOutBr = keyframes`
  0% {
    transform: scale(1);
    transform-origin: 0% 100%;
    opacity: 1;
  }
  100% {
    transform: scale(0);
    transform-origin: 0% 100%;
    opacity: 1;
  }
`;

const Wrapper = styled.nav`
	display: block;

	> div:first-child {
		height: ${NAV_HEIGHT};
		background: ${({$background}) => $background};
	}

	> div:nth-child(2) {
		// the blue wrapper
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: ${NAV_HEIGHT};
		z-index: 5;
		background: ${({$background}) => $background};
		will-change: transform;
		transform: translateY(-100px);
		transition: all 0.5s;

		> div {
			// the wrapper content
			display: flex;
			align-items: center;
			width: 100%;
			max-width: ${CONTAINER_WIDTH};
			margin: 0 auto;
			height: ${NAV_HEIGHT};
		}
	}

	.mobile-wrapper {
		// the full screen wrapper for mobile menu overlay
		position: fixed;
		background: ${PRIMARY_COLOR_2};
		top: 0;
		left: 0;
		z-index: 100;
		width: 100vw;
		height: 100vh;
		display: none;

		&.show {
			animation: ${scaleInTr} 0.5s cubic-bezier(0.6, -0.28, 0.735, 0.045) both;
			display: block;
		}

		&.out {
			animation: ${scaleOutBr} 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
		}

		> nav:nth-child(1) {
			// wrapper for the head, logo and close button
			display: flex;
			align-items: center;
			width: 100%;

			> div:nth-child(1),
			> div:nth-child(3) {
				width: 80px;
				text-align: center;

				> button {
					border: 2px solid #ffffff;
					background: transparent;
					width: 40px;
					height: 40px;
					margin: 0;
					padding: 0;
					border-radius: 100%;
					outline: none;

					> span {
						display: block;
						text-align: center;
						padding: 0;
						line-height: normal;
						color: #ffffff;
						font-size: 1.5rem;
						font-weight: normal;
					}
				}
			}

			> div:nth-child(2) {
				text-align: center;
				flex: 1;
				padding: 1rem 0;
				box-sizing: border-box;

				> a {
					margin: 0 auto;
				}
			}
		}

		> nav:nth-child(2) {
			// the links list wrapper
			display: block;
			margin: 1rem auto;
			width: 100%;
			max-width: 380px;

			> a {
				display: block;
				padding: 0.75rem;
				box-sizing: border-box;
				text-align: center;
				font-weight: normal;
				font-size: 1rem;
				color: #ffffff;
				background: ${PRIMARY_COLOR_3};
				border-radius: 5px;
				margin: 0.75rem 0;
				border: 2px solid transparent;

				&.highlight {
					background: ${PRIMARY_COLOR};
				}
			}
		}
	}

	&.in-scroll {
		> div:nth-child(2) {
			// the blue wrapper
			box-shadow: 0 8px 16px rgba(255, 255, 255, 0.75);
		}
	}

	&.show {
		> div:nth-child(2) {
			// the blue wrapper
			transform: translateY(0);
		}
	}

	.logo {
		display: block;
		vertical-align: middle;
		width: min-content;
		white-space: nowrap;
		font-size: 1.5rem;
		color: #ffffff;
		font-family: ${FONT_FAMILY};
		padding: 0.25rem 0.75rem;
		box-sizing: border-box;
		font-weight: bold;
	}

	.flex {
		flex: 1;
	}

	.link {
		padding: 0 0.15rem;
		box-sizing: border-box;
		width: min-content;
		height: auto;
		white-space: nowrap;
		display: none;

		> a {
			display: block;
			font-size: 0.825rem !important;
			font-weight: normal;
			color: #ffffff;
			border: 2px solid transparent;
			padding: 0.25rem 0.75rem;
			box-sizing: border-box;
			border-radius: 5px;

			@media (hover: hover) and (pointer: fine) {
				&:hover {
					text-decoration: underline;
				}
			}
		}

		&.sign-in {
			display: block;

			> a {
				border-color: #ffffff;

				&:hover {
					color: ${PRIMARY_COLOR_2};
					background: #ffffff;
					text-decoration: none;
				}
			}
		}

		&:last-child {
			padding-right: 0.85rem;
		}

		&.mobile-link {
			display: block;
			margin-left: 0.75rem;
		}
	}

	.mobile-button {
		border: none;
		padding: 0;
		width: 40px;
		height: 40px;
		box-sizing: border-box;
		border-radius: 5px;
		font-size: 0.825rem !important;
		background: transparent;
		vertical-align: middle;
		margin: 0;
		outline: none;
		appearance: none;
		position: relative;

		> span {
			display: block;
			width: 60%;
			left: 20%;
			border-top: 3px solid #ffffff;
			position: absolute;

			&:nth-child(1) {
				top: calc(25% + 1px);
			}

			&:nth-child(2) {
				top: calc(50% - 1px);
			}

			&:nth-child(3) {
				top: calc(75% - 3px);
			}
		}
	}

	@media (min-width: 992px) {
		.link {
			display: block;

			&.mobile-link {
				display: none;
			}

			&.sign-in {
				margin-right: 0.85rem;
			}
		}
	}
`;
