import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';
import styled, {createGlobalStyle} from 'styled-components';
import CookieConsent from 'react-cookie-consent';
import {graphql, Link, useStaticQuery} from 'gatsby';

import CSSReset from '../ui/CSSReset';
import {FONT_FAMILY, PRIMARY_COLOR, PRIMARY_COLOR_3} from '../constants';
import {cookiesRoute} from '../routes';

const buttonStyle = {
	fontFamily: FONT_FAMILY,
	fontSize: '0.9rem',
	fontWeight: 'bold',
	background: PRIMARY_COLOR,
	color: '#ffffff',
	borderRadius: '4px'
};

const style = {
	background: PRIMARY_COLOR_3,
	boxShadow: '-4px 0 0.66rem rgba(255, 255, 255, 0.9)',
	transform: 'translateY(200px)',
	animation: 'scale-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both'
};

export default function Layout({children, title = null, description = null, uri = null, ogImage = null}) {
	const data = useStaticQuery(graphql`
		query UrlQuery {
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const domainWithSchema = data.site.siteMetadata.siteUrl;
	let domain = domainWithSchema;

	const url = uri === null ? null : `${domain}${uri}`;

	if (domain.startsWith('https://')) {
		domain = domain.substr(8, domain.length - 8);
	}

	if (domain.startsWith('http://')) {
		domain = domain.substr(7, domain.length - 7);
	}

	return (
		<>
			<CSSReset />
			<GlobalCSS />
			<Helmet title={title} defer={false}>
				<meta charSet="utf-8" />
				<link href="https://qr-cjenik.fra1.cdn.digitaloceanspaces.com" rel="preconnect" crossOrigin />
				<link href="https://app.qr-cjenik.hr" rel="preconnect" crossOrigin />
				<link href="https://web.qr-cjenik.hr" rel="preconnect" crossOrigin />
				<link href="https://nas.qr-cjenik.hr" rel="preconnect" crossOrigin />
				{description && <meta name="description" content={description} />}

				{url && <meta property="og:url" content={url} />}
				<meta property="og:type" content="website" />
				<meta property="og:title" content={title} />
				{description && <meta property="og:description" content={description} />}
				{ogImage && <meta property="og:image" content={domainWithSchema + ogImage.src} />}

				<meta name="twitter:card" content="summary_large_image" />
				<meta property="twitter:domain" content={domain} />
				{url && <meta property="twitter:url" content={url} />}
				<meta name="twitter:title" content={title} />
				<meta name="twitter:description" content={description} />
				{ogImage && <meta name="twitter:image" content={domainWithSchema + ogImage.src} />}
			</Helmet>
			{children}
			<CookieConsent
				location="bottom"
				buttonText="Prihvaćam"
				cookieName="qr-cjenik-cookie-consent"
				buttonStyle={buttonStyle}
				expires={365}
				style={style}
			>
				<CookieConsentText>
					<p>
						Pregledavanjem i korištenjem naše internet stranice slažete se s korištenjem kolačića (eng. cookies) u skladu s našom{' '}
						<Link to={cookiesRoute()}>Politikom privatnosti</Link>.
					</p>
				</CookieConsentText>
			</CookieConsent>
		</>
	);
}

Layout.propTypes = {
	children: PropTypes.node,
	title: PropTypes.string,
	description: PropTypes.string,
	uri: PropTypes.string,
	// eslint-disable-next-line
	ogImage: PropTypes.object
};

const GlobalCSS = createGlobalStyle`
	html {
		font-size: 16px;
	}
	
	@media (min-width: 992px) {
		html {font-size: 17px;}
	}
	
	@media (min-width: 1200px) {
		html {font-size: 18px;}
	}
	
	@media (min-width: 1600px) {
		html {font-size: 19px;}
	}
	
	body {
    font-family: ${FONT_FAMILY};
    -webkit-font-smoothing: antialiased;
		background: #f1f1f1;
	}

	.text-left {
		text-align: left;
	}

	.text-center {
		text-align: center;
	}

	.text-right {
		text-align: right;
	}

	.mt-025 {
		margin-top: 0.25rem;
	}

	.mt-05 {
		margin-top: 0.5rem;
	}

	.mt-1 {
		margin-top: 1rem;
	}

	.mt-2 {
		margin-top: 2rem;
	}

	.mt-3 {
		margin-top: 3rem;
	}

	.mt-4 {
		margin-top: 4rem;
	}

	.mt-5 {
		margin-top: 5rem;
	}

	.mr-1 {
		margin-right: 1rem;
	}

	.mb-1 {
		margin-bottom: 1rem;
	}

	.mb-2 {
		margin-bottom: 2rem;
	}

	.mb-3 {
		margin-bottom: 3rem;
	}

	.mb-4 {
		margin-bottom: 4rem;
	}

	.mb-5 {
		margin-bottom: 5rem;
	}
	
	.ml-auto {
		margin-left: auto;
	}
	
	.mr-auto {
		margin-right: auto;
	}
	
	.img-max-500 {
		width: 100%;
		max-width: 500px;

		> img {
			width: 100%;
		}
	}

  @-webkit-keyframes scale-in-bottom {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
      -webkit-transform-origin: 50% 100%;
      transform-origin: 50% 100%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transform-origin: 50% 100%;
      transform-origin: 50% 100%;
      opacity: 1;
    }
  }
  @keyframes scale-in-bottom {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
      -webkit-transform-origin: 50% 100%;
      transform-origin: 50% 100%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transform-origin: 50% 100%;
      transform-origin: 50% 100%;
      opacity: 1;
    }
  }
`;

const CookieConsentText = styled.div`
	display: block;

	> p {
		font-size: 0.9rem;
	}

	a {
		color: #ffffff;
		text-decoration: underline;
	}
`;
