export const homepageRoute = () => '/';

export const howItWorksRoute = () => '/#kako-radi';

export const examplesRoute = () => '/#primjeri';

export const faqRoute = () => '/#faq';

export const whyQrCjenikRoute = () => '/#zasto-qr-cjenik';

export const restaurantsRoute = () => '/restorani/';

export const barsRoutes = () => '/barovi/';

export const pubsRoute = () => '/pivnice/';

export const nightClubsRoute = () => '/nocni-klubovi/';

export const pasteryShopsRoute = () => '/slasticarnice/';

export const hotelsRoute = () => '/hoteli/';

export const termsOfUseRoute = () => '/uvjeti-koristenja/';

export const privacyPolicyRoute = () => '/politika-privatnosti/';

export const contactRoute = () => '/kontakt/';

export const pricesRoute = () => '/#cijene';

export const aboutBasicRoute = () => '/paket/basic/';

export const aboutStandardRoute = () => '/paket/standard/';

export const aboutPremiumRoute = () => '/paket/premium/';

export const cookiesRoute = () => '/politika-privatnosti/#cookies';

export const blogRoute = () => '/#blog';
