import React from 'react';
import PropTypes from 'prop-types';
import {graphql, StaticQuery} from 'gatsby';
import EnvContext from '../context/EnvContext';

export default function EnvData({children}) {
	return (
		<StaticQuery
			query={graphql`
				query EnvQuery {
					site {
						siteMetadata {
							title
							description
							siteUrl
							appDomain
						}
					}
				}
			`}
			render={(data) => {
				let domain = data.site.siteMetadata.appDomain;

				if (domain.startsWith('https://')) {
					domain = domain.substr(8, domain.length - 8);
				}

				if (domain.startsWith('http://')) {
					domain = domain.substr(7, domain.length - 7);
				}

				return (
					<EnvContext.Provider
						value={{
							title: data.site.siteMetadata.title,
							description: data.site.siteMetadata.description,
							siteUrl: data.site.siteMetadata.siteUrl,
							appDomain: data.site.siteMetadata.appDomain,
							domain
						}}
					>
						{children}
					</EnvContext.Provider>
				);
			}}
		/>
	);
}

EnvData.propTypes = {
	children: PropTypes.node
};
