export const PRIMARY_COLOR = '#0099ff';

export const PRIMARY_COLOR_1 = '#007fd7';

export const PRIMARY_COLOR_2 = '#0069b6';

export const PRIMARY_COLOR_3 = '#005ca0';

export const CONTAINER_WIDTH = '1200px';

export const CONTAINER_BLOG_WIDTH = '800px';

export const NAV_HEIGHT = '70px';

export const FONT_FAMILY =
	'Ubuntu, Source Sans Pro,-apple-system,Helvetica Neue,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Open Sans,sans-serif';

export const TEXT_COLOR = '#222222';

export const TEXT_MUTED1 = '#555555';

export const TEXT_MUTED2 = '#777777';
